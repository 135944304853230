<template>
  <v-footer
      color="primary"
      bottom
      fixed
      padless
  >
    <v-row
        justify="center"
        no-gutters
    >

      <v-col
          class="primary py-4 text-center white--text"
          cols="12"
      >
        {{ new Date().getFullYear() }} — <strong>Hella Gutmann</strong>
      </v-col>
    </v-row>
  </v-footer>
</template>
<script>

export default {
  name: 'Footer',

}
</script>
<style scoped>
.label {
  margin: 10px;

}

.footer {
  background-color: #007d40;
  position: absolute;
  text-align: right;
  height: 60px;
  bottom: 0;
  width: 99%;
}

</style>
